<script>
export default {
  data () {
    return {
      goodsTypeList: [
        {
          value: 'all',
          label: this.$t('GoodsType.All')
        },
        {
          value: 'Card',
          label: this.$t('GoodsType.Card')
        },
        {
          value: 'Point',
          label: this.$t('GoodsType.Point')
        },
        {
          value: 'Gem',
          label: this.$t('GoodsType.Gem')
        },
        {
          value: 'ActPoint',
          label: this.$t('GoodsType.ActPoint')
        },
        {
          value: 'LaijiCoin',
          label: this.$t('GoodsType.LaijiCoin')
        },
        {
          value: 'Lottery',
          label: this.$t('GoodsType.Lottery')
        },
        {
          value: 'Combination',
          label: this.$t('GoodsType.Combination')
        },
        {
          value: 'CampaignLimited',
          label: this.$t('GoodsType.CampaignLimited')
        },
        {
          value: 'Membership',
          label: this.$t('GoodsType.Membership')
        },
        {
          value: 'LinePoints',
          label: this.$t('GoodsType.LinePoints')
        }
      ],
      goodsUsageList: [
        {
          value: 'all',
          label: this.$t('GoodsUsage.All')
        },
        {
          value: 'Membership',
          label: this.$t('GoodsUsage.Membership')
        },
        {
          value: 'FreeGame',
          label: this.$t('GoodsUsage.FreeGame')
        },
        {
          value: 'ActPointExchange',
          label: this.$t('GoodsUsage.ActPointExchange')
        },
        {
          value: 'LaijiCoinExchange',
          label: this.$t('GoodsUsage.LaijiCoinExchange')
        },
        {
          value: 'PointExchange',
          label: this.$t('GoodsUsage.PointExchange')
        },
        {
          value: 'GemRecharge',
          label: this.$t('GoodsUsage.GemRecharge')
        },
        {
          value: 'Lottery',
          label: this.$t('GoodsType.Lottery')
        },
        {
          value: 'Combination',
          label: this.$t('GoodsUsage.Combination')
        },
        {
          value: 'CampaignLimited',
          label: this.$t('GoodsUsage.CampaignLimited')
        },
        {
          value: 'LinePoints',
          label: this.$t('GoodsType.LinePoints')
        }
      ]
    }
  }
}
</script>
