<template lang="pug">
  Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t("Add goods") : $t("Edit goods") }}

    Form.modal-editor(
      :model="formData"
      ref="goodsEditorForm"
      :rules="goodsEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="name")
            Row
              Col.title(span="9")
                h3 {{$t('Goods name')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.name"
                )

          FormItem(prop="type")
            Row
              Col.title(span="9")
                h3 {{$t('Goods type')}}
              Col(span="14" offset="1")
                Select.app-tool(
                  v-model="formData.type"
                  style="width: 100%;"
                )
                  Option(v-for="option in goodsTypeList", :value="option.value", :key="option.value") {{ option.label }}

          FormItem(prop="usage")
            Row
              Col.title(span="9")
                h3 {{$t('Goods usage')}}
              Col(span="14" offset="1")
                Select.app-tool(
                  v-model="formData.usage"
                  style="width: 100%;"
                )

                  Option(v-for="option in goodsUsageList", :value="option.value", :key="option.value") {{ option.label }}

          FormItem(prop="description")
            Row
              Col.title(span="9")
                h3 {{$t('Goods description')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.description"
                  type="textarea"
                  :rows="4"
                )

          FormItem(prop="data")
            Row
              Col.title(span="9")
                h3 {{$t('Goods data')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.data"
                  type="textarea"
                  :rows="4"
                )

          FormItem(prop="code")
            Row
              Col.title(span="9")
                h3 {{$t('Goods code')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.code"
                  :rows="4"
                )

          FormItem(prop="actPointPrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods actPoint price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.actPointPrice"
                  :rows="4"
                )

          FormItem(prop="actPointReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods actPoint reward')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.actPointReward"
                  :rows="4"
                )

          FormItem(prop="laijiCoinPrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods laijiCoin price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.laijiCoinPrice"
                  :rows="4"
                )

          FormItem(prop="laijiCoinReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods laijiCoin reward')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.laijiCoinReward"
                  :rows="4"
                )

          FormItem(prop="gemPrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods gem price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.gemPrice"
                  :rows="4"
                )

          FormItem(prop="gemReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods gem reward')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.gemReward"
                  :rows="4"
                )

          FormItem(prop="pointPrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods point price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.pointPrice"
                  :rows="4"
                )

          FormItem(prop="pointReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods point reward')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.pointReward"
                  :rows="4"
                )

          FormItem(prop="cashPrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods cash price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.cashPrice"
                  :rows="4"
                )

          FormItem(prop="vipPointReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods vip point reward')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.vipPointReward"
                  :rows="4"
                )

          FormItem(prop="stakePrice")
            Row
              Col.title(span="9")
                h3 {{$t('Goods stake price')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.stakePrice"
                  :rows="4"
                )

          FormItem(prop="discount")
            Row
              Col.title(span="9")
                h3 {{$t('Goods discount')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.discount"
                  :rows="4"
                )

          FormItem(prop="eShopId")
            Row
              Col.title(span="9")
                h3 {{$t('Goods eshop id')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.eShopId"
                  :rows="4"
                )

          FormItem(prop="isHot")
            Row
              Col.title(span="9")
                h3 {{$t('Goods hot')}}
              Col.val(span="12" offset="3")
                i-switch(v-model="formData.isHot")
                  Icon(slot="open" type="md-checkmark")
                  Icon(slot="close" type="'md-close")

          FormItem(prop="isFirst")
            Row
              Col.title(span="9")
                h3 {{$t('Goods first')}}
              Col.val(span="12" offset="3")
                i-switch(v-model="formData.isFirst")
                  Icon(slot="open" type="md-checkmark")
                  Icon(slot="close" type="'md-close")

          FormItem(prop="images")
            Row
              Col.title(span="9")
                h3 {{$t('Upload goods image')}}

              Col(span="7" offset="1")
                Upload.uploader(
                  :accept="acceptFormats"
                  :show-upload-list="false"
                  :headers="uploadHeaders"
                  :action="uploadUrl"
                  :on-progress="onProgress('thumbnail')"
                  :on-success="onSuccess('thumbnail')"
                  :on-error="onError('thumbnail')"
                  :format="fileFormats"
                )
                  Button.upload-btn(
                    :loading="uploading.thumbnail"
                    :type="formData.thumbnail ? 'primary' : 'default'"
                    ) {{ getFileName('thumbnail') || $t('Upload goods thumbnail') }}

              Col(span="7")
                Upload.uploader(
                  :accept="acceptFormats"
                  :show-upload-list="false"
                  :headers="uploadHeaders"
                  :action="uploadUrl"
                  :on-progress="onProgress('image')"
                  :on-success="onSuccess('image')"
                  :on-error="onError('image')"
                  :format="fileFormats"
                )
                  Button.upload-btn(
                    :loading="uploading.image"
                    :type="formData.image ? 'primary' : 'default'"
                    ) {{ getFileName('image') || $t('Upload goods image') }}

          FormItem(prop="mechanism")
            Row
              Col.title(span="9")
                h3 {{$t('Game mechanism')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.mechanism"
                )
                  Option(v-for="item in slots" :value="item.value" :key="item.value") {{ item.label }}

          FormItem(prop="operators")
            Row
              Col.title(span="9")
                h3 {{$t('Goods operators')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.operators"
                )
                  Option(v-for="item in operators" :value="item._id" :key="item._id") {{ item.fullname }}
            Row(style="padding-top: 0px;")
              Col(span="14" offset="10")
                Button(
                  @click="uncheckAllOperators"
                  type='warning'
                ) {{ $t('Uncheck all') }}

          FormItem(prop="provider")
            Row
              Col.title(span="9")
                h3 {{$t('Goods provider')}}
              Col(span="14" offset="1")
                Select(
                  v-model="formData.provider"
                  @on-change="onProviderChange"
                )
                  Option(v-for="item in providers" :value="item._id" :key="item._id") {{ item.fullname }}

          FormItem(prop="games")
            Row
              Col.title(span="9")
                h3 {{$t('Goods games')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.games"
                )
                  Option(v-for="item in games" :value="item._id" :key="item._id") {{ item.name }}
            Row(style="padding-top: 0px")
              Col(span="14" offset="10")
                Button(
                  type='warning'
                  @click="uncheckAllGames"
                ) {{ $t('Uncheck all') }}

          FormItem(prop="gameClass")
            Row
              Col.title(span="9")
                h3 {{$t('Game class')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.gameClass"
                )
                  Option(v-for="item in gameClassList" :value="item.value" :key="item.value") {{ item.label }}

    .footer(slot='footer')
      Button(icon="md-close" @click="onClose" :disabled="isUpdating") {{$t('Cancel')}}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
        v-if="loginUserInfo.isAdministrator"
      ) {{$t('Confirm')}}

</template>
<script>
import { mapGetters } from 'vuex'
import NumberUtil from '~m/number-util'
import StringUtil from '~m/string-util'
import Currency from '~m/currency'
import Goods from '~m/goods'
import Time from '~m/time'

export default {
  name: 'goods-editor',
  mixins: [NumberUtil, StringUtil, Time, Currency, Goods],
  data () {
    return {
      // games: [],
      fileFormats: ['png', 'jpg', 'jpeg'],
      acceptFormats: '.png,.jpg,.jpeg',
      isUpdating: false,
      goodsTypes: ['Card', 'Gem', 'Point', 'ActPoint', 'LaijiCoin'],
      goodsUsages: ['FreeGame', 'GemRecharge', 'PointExchange', 'ActPointExchange', 'LaijiCoinExchange'],
      gameClassList: [
        {
          value: '0',
          label: this.$t('class0')
        },
        {
          value: '1',
          label: this.$t('class1')
        },
        {
          value: '2',
          label: this.$t('class2')
        },
        {
          value: '3',
          label: this.$t('class3')
        },
        {
          value: '4',
          label: this.$t('class4')
        }
      ],
      uploading: {
        thumbnail: false,
        image: false
      },
      slots: [
        {
          label: this.$t('mahjong'),
          value: 'mahjong'
        },
        {
          label: this.$t('slot1'),
          value: 'slot1'
        },
        {
          label: this.$t('slot2'),
          value: 'slot2'
        },
        {
          label: this.$t('slot3'),
          value: 'slot3'
        }
      ]
    }
  },
  props: {
    formData: Object,
    loginUserInfo: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  watch: {
    gameList (nv, ov) {
      this.games = nv
    },
    formData: {
      async handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          if (this.editType === 'new') {
            this.formData.provider = this.providers[0]._id
            this.formData.operators = this.operators.map(operator => operator._id)
            this.formData.games = this.games.map(g => g._id)
          }
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    // 嵌入營運商的 state 資料
    ...mapGetters('operator', { operators: 'getAllForSelector' }),
    ...mapGetters('provider', { providers: 'getAllForSelector' }),
    ...mapGetters('game', { games: 'getAllForSelector' }),
    ...mapGetters('auth', ['userInfo']),
    goodsEditorRule () {
      return {
        name: [{ required: true, message: this.$t(`Input goods name`), trigger: 'blur' }],
        type: [{ required: true, message: this.$t(`Please select goods type`), trigger: 'change' }],
        usage: [{ required: true, message: this.$t(`Please select goods usage`), trigger: 'change' }],
        code: [{ required: true, validator: this.verifyCode(), trigger: 'blur' }],
        description: [{ required: true, message: this.$t(`Input goods description`), trigger: 'blur' }],
        stakePrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        cashPrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        gemPrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        gemReward: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        vipPointReward: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        pointPrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        pointReward: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        actPointPrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        actPointReward: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        laijiCoinPrice: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        laijiCoinReward: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        operators: [{ required: true, validator: this.verifySelector('operators'), trigger: 'change' }],
        provider: [{ required: true, validator: this.verifySelector('provider'), trigger: 'change' }],
        games: [{ required: true, validator: this.verifySelector('games'), trigger: 'change' }],
        mechanism: [{ required: true, validator: this.verifySelector(`mechanism`), trigger: 'change' }]
      }
    },
    uploadUrl () {
      return `${process.env.VUE_APP_API}/upload`
    },

    uploadHeaders () {
      return {
        'X-Token': this.userInfo.token
      }
    }
  },
  methods: {
    async onProviderChange (val) {
      if (!_.isArray(val)) val = [val]
      this.formData.games = this.games.map(g => {
        if (val.includes(g.provider)) return g._id
      })
    },

    uncheckAllGames () {
      this.formData.games = []
    },

    uncheckAllOperators () {
      this.formData.operators = []
    },

    verifyOperators (rule, value, callback) {
      if (!value.length) {
        return callback(new Error(this.$t(`Select goods operators`)))
      }
      callback()
    },

    verifySelector (field) {
      return (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t(`Select goods ${field}`)))
        }
        callback()
      }
    },
    verifyNumber (rule, value, callback) {
      if (!value && value * 1 !== 0) {
        return callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value) && value * 1 !== 0) {
        return callback(new Error(this.$t(`Input number`)))
      }
      callback()
    },

    verifyCode () {
      return (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t(`Input goods code`)))
        } else if (!/^[a-zA-Z0-9-_]+$/ig.test(value)) {
          return callback(new Error(this.$t(`Only a-z, 0-9 and - are allowed`)))
        }
        callback()
      }
    },
    getOffset (data, index) {
      let offset = null
      const length = data.length - index
      if (index % 4 === 0 && length < 4) {
        offset = {
          marginLeft: `${(4 - length) % 4 * 6 / 24 * 100}%`
        }
      }
      return offset
    },
    isArray (data) {
      return _.isArray(data)
    },
    handleSubmit () {
      this.$refs.goodsEditorForm.validate(valid => {
        // console.log(`this.formData.provider: ${this.formData.provider}`)

        if (!valid) return false
        // this.isUpdating = true
        this.parseObjectNumber(this.formData)
        this.onUpdate()
      })
    },
    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }

      this.formData.isReady = true
    },

    onProgress (path) {
      return (event, file, fileList) => {
        this.uploading[path] = true
      }
    },

    onSuccess (path) {
      return (response, file, fileList) => {
        this.uploading[path] = false
        this.formData[path] = response.data
      }
    },

    onError (path) {
      return (error, file, fileList) => {
        this.uploading[path] = false
        this.formData[path] = null
        console.log(error)
      }
    },

    getFileName (path) {
      if (!path) return null
      return _.get(this.formData[path], 'name')
    }
  },
  async mounted () {
  }
}
</script>
<style lang="sass" scoped>
  .full-width
    width: 100%

  .align-left
    text-align: left

  .operator-name
    margin: 0 0 4px 4px
    background: #999
    padding: 0 8px
    line-height: 24px
    color: #fff
    display: inline-block

  .goods-link
    margin-bottom: 12px

  .uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

  .upload-btn
    width: 130px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

</style>
