<template lang="pug">
  .table
    AppTable.app-table(:columns="columns", :data="listData", border)
      template(
        slot-scope="{ row, index }"
        slot="name"
      )
        Avatar(:src="getGoodsThumb(row)", shape="square", size='small', :style="{marginRight: '8px'}")
        span.pointer(
          @click="searchGood(row.name)"
        ) {{ row.name }}

      template(
        slot-scope="{ row, index }"
        slot="gameClass"
      )
        span {{ row.gameClass.join(', ') }}

      template(
        slot-scope="{ row, index }"
        slot="type"
      )
        span {{ $t(`GoodsType.${row.type}`) }}

      template(
        slot-scope="{ row, index }"
        slot="usage"
      )
        span {{ $t(`GoodsUsage.${row.usage}`) }}

      template(
        slot-scope="{ row, index }"
        slot="cashPrice"
      )
        span {{ currencyFormat(row.cashPrice, userInfo) }}

      template(
        slot-scope="{ row, index }"
        slot="gemPrice"
      )
        span {{ currencyFormat(row.gemPrice, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="gemReward"
      )
        span {{ currencyFormat(row.gemReward, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="pointPrice"
      )
        span {{ currencyFormat(row.pointPrice, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="pointReward"
      )
        span {{ currencyFormat(row.pointReward, { noSymbol: true, digits: 0 }) }}

      template(
        slot-scope="{ row, index }"
        slot="actPointPrice"
      )
        span {{ currencyFormat(row.actPointPrice, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="actPointReward"
      )
        span {{ currencyFormat(row.actPointReward, { noSymbol: true, digits: 0 }) }}

      template(
        slot-scope="{ row, index }"
        slot="laijiCoinPrice"
      )
        span {{ currencyFormat(row.laijiCoinPrice, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="laijiCoinReward"
      )
        span {{ currencyFormat(row.laijiCoinReward, { noSymbol: true, digits: 0 }) }}

      template(
        slot-scope="{ row, index }"
        slot="vipPointReward"
      )
        span {{ currencyFormat(row.vipPointReward, { noSymbol: true, digits: 0 }) }}

      template(
        slot-scope="{ row, index }"
        slot="stakePrice"
      )
        span {{ currencyFormat(row.stakePrice, { noSymbol: true }) }}

      template(
        slot-scope="{ row, index }"
        slot="isFirst"
      )
        Icon(:type="row.isFirst ? 'md-checkmark' : 'md-close'", :style="{color: row.isFirst ? '#19be6b' : '#808695'}")

      template(
        slot-scope="{ row, index }"
        slot="isSale"
      )
        Icon(:type="row.isSale ? 'md-checkmark' : 'md-close'", :style="{color: row.isSale ? '#19be6b' : '#808695'}")

      template(
        slot-scope="{ row, index }"
        slot="actions"
      )
        Button(
          type="success",
          size='small',
          icon="md-create",
          @click="editGoods(row)"
        )

    GoodsEditor(
      :visible="goodsEditorVisible"
      :login-user-info="userInfo",
      :form-data="goodsData",
      :edit-type="goodsEditorType"
      :on-update="onGoodsUpdate"
      :on-close="closeGoodsEditor"
    )
</template>
<script>
import NumberUtil from '~m/number-util'
import Currency from '~m/currency'
import Time from '~m/time'
import Thumbnail from '~m/thumbnail'
import AppTable from '~c/app-table'
import GoodsEditor from '~v/goods/goods-editor'
import { mapActions, mapGetters } from 'vuex'
import to from 'await-to-js'

export default {
  name: 'goods-table',
  mixins: [
    Currency,
    NumberUtil,
    Time,
    Thumbnail
  ],
  components: {
    AppTable,
    GoodsEditor
  },
  data () {
    return {
      goodsData: null,
      goodsEditorType: '',
      goodsEditorVisible: false
    }
  },
  props: {
    listData: Array,
    hasOperator: Boolean,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    ...mapGetters('game', { games: 'getAllForSelector' }),
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: 'ID',
          key: 'goodsId',
          width: 64,
          align: 'center'
        },
        // {
        //   title: this.$t('Created at'),
        //   slot: 'createdAt',
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: this.$t('Goods name'),
          slot: 'name',
          ellipsis: true,
          width: 150
        },
        // {
        //   title: this.$t('Goods code'),
        //   key: 'code',
        //   ellipsis: true
        // },
        {
          title: this.$t('Goods type'),
          slot: 'type',
          ellipsis: true,
          width: 120
        },
        {
          title: this.$t('Goods usage'),
          slot: 'usage',
          ellipsis: true,
          width: 120
        },
        // {
        //   title: this.$t('Goods description'),
        //   key: 'description',
        //   ellipsis: true
        // },
        {
          title: this.$t('Goods cash price'),
          slot: 'cashPrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods gem price'),
          slot: 'gemPrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods point price'),
          slot: 'pointPrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods actPoint price'),
          slot: 'actPointPrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods laijiCoin price'),
          slot: 'laijiCoinPrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods gem reward'),
          slot: 'gemReward',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods point reward'),
          slot: 'pointReward',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods actPoint reward'),
          slot: 'actPointReward',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods laijiCoin reward'),
          slot: 'laijiCoinReward',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods vip point reward'),
          slot: 'vipPointReward',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods stake price'),
          slot: 'stakePrice',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('First time goods'),
          slot: 'isFirst',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('On sale goods'),
          slot: 'isSale',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Game class'),
          slot: 'gameClass',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Goods switch'),
          key: 'actived',
          render: this.renderSwitchActived,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 66
        }
      ]

      return columns
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('game', { getGames: 'findAll' }),
    ...mapActions('goods', {
      goodsUpdate: 'update',
      findGoods: 'findOne'
    }),
    renderSwitchActived (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('goods/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchPoster (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.isPoster
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                isPoster: !row.isPoster
              }
              dispatch('goods/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    renderSwitchNotify (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.isNotify
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                isNotify: !row.isNotify
              }
              dispatch('goods/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    async onGoodsUpdate () {
      if (!this.goodsData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeGoodsEditor()
      }

      let meta = this.goodsData.data
      if (meta) JSON.parse(meta)
      let data = {
        ...this.goodsData,
        data: meta ? JSON.parse(meta) : {}
      }

      console.log(data)
      let [err] = await to(this.goodsUpdate({ id: this.goodsData.id, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit goods error'))
      }

      this.$Message.success(this.$t('Edit goods successful'))
      this.closeGoodsEditor()
    },

    async editGoods (row) {
      let mydata = _.cloneDeep(row)

      if (mydata.data) mydata.data = JSON.stringify(mydata.data)
      this.goodsData = mydata
      this.goodsEditorType = 'edit'
      this.goodsEditorVisible = true
    },

    closeGoodsEditor () {
      this.goodsEditorVisible = false
      setTimeout(() => {
        this.goodsEditorType = null
        this.goodsData = null
      }, 500)
    },

    searchGood (name) {
      this.$emit('after-click-name', name)
    }
  }
}
</script>
<style lang="sass" scoped>
.goods-unpublish
  color: #19be6b

.goods-publish
  color: #ed4014

.goods-expired
  color: #ccc
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
