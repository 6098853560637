<template lang="pug">
.content(
  v-infinite-scroll="fetchData"
  infinite-scroll-disabled="isGoodsBusy"
  :infinite-scroll-distance="scrollDistance"
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Affix(
      :offset-top="62"
      @on-change="searchAffixChange"
      :class="affixClass"
    )
      Row
        Col(span='24')
          Select.app-tool(
            v-model="type"
            :disabled="goodsListLoading"
            prefix="md-apps"
            @on-change="onTypeChange"
          )
            Option(v-for="(option, index) in goodsTypeList" :value="option.value" :key="option.value") {{ option.label }}

          Select.app-tool(
            v-model="usage"
            :disabled="goodsListLoading"
            prefix="md-apps"
            @on-change="onUsageChange"
          )
            Option(v-for="(option, index) in goodsUsageList" :value="option.value" :key="option.value") {{ option.label }}

          Select.app-tool(
            v-model="gameclass"
            :disabled="goodsListLoading"
            prefix="md-apps"
            :placeholder="$t('Select game class')"
            @on-change="onGameClassChange"
          )
            Option(v-for="(option, index) in gameClassList" :value="option.value" :key="option.value") {{ option.label }}

          Input.app-tool(
            v-model="search"
            :disabled="goodsListLoading"
            icon="ios-search"
            clearable
            :placeholder="$t('Search goods')"
            @on-change='onSearchInputChange'
          )

          Button.app-tool(
            icon="md-add"
            type="primary"
            style="float: right;"
            @click="newGoods()"
          ) {{$t('Add goods')}}
    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24', v-if="goodsList.length || !goodsListLoading")
        GoodsTable(
          :list-data="goodsList"
          :user-info="userInfo"
          @after-click-name="afterClickName"
        )

      Col(span='24', class="table-loading-col" v-if="goodsListLoading")
        Spin(fix)
          Icon(type="ios-loading" size=18 class="spin-icon")

    GoodsEditor(
      :visible="goodsEditorVisible"
      :login-user-info="userInfo"
      :form-data="goodsData"
      :edit-type="goodsEditorType"
      :on-update="onGoodsUpdate"
      :on-close="closeGoodsEditor"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodsTable from '~v/goods/goods-table'
import GoodsEditor from '~v/goods/goods-editor'
import Scroll from '~m/scroll'
import Goods from '~m/goods'
import RouterEvents from '~m/router-events'
import moment from 'moment'
import to from 'await-to-js'

export default {
  name: 'goods',
  components: {
    GoodsTable,
    GoodsEditor
  },
  mixins: [Scroll, Goods, RouterEvents],
  data () {
    return {
      date: moment().startOf('d').format('YYYY-MM-DD'),
      search: '',
      type: 'all',
      usage: 'all',
      gameclass: '0',
      goodsData: null,
      goodsEditorType: '',
      goodsEditorVisible: false,
      dateOptions: {
        shortcuts: [
          {
            text: this.$t('Today'),
            value () {
              return moment().startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Yesterday'),
            value () {
              return moment().add(-1, 'd').startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Last week'),
            value () {
              return moment().add(-1, 'w').startOf('d').format('YYYY-MM-DD')
            }
          }
        ]
      }
    }
  },
  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      // pid 不一樣要更新 operator list
      this.resetSelector()
    },
    allOperators (nv, ov) {
      this.operatorOptions = [
        this.defaultOperators,
        ...nv
      ]
      this.operator = this.$route.query.operator
    }
  },
  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    ...mapGetters('goods', {
      goodsList: 'getListData',
      goodsListLoading: 'getLoadingStatus',
      hasNextGoods: 'hasNext'
    }),

    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),

    hasOperator () {
      return /[0-9]+/.test(`${this.operator}`)
    },
    // 遊戲內容是否在讀取
    isGoodsBusy () {
      return this.goodsListLoading || !this.hasNextGoods
    }
  },

  methods: {
    ...mapActions('goods', { findGoods: 'find', resetGoods: 'reset', createGoods: 'create' }),
    fetchData (reset = false) {
      let { operator, search, type, usage, gameclass } = this.$route.query
      search = search === '' ? null : search
      if (type === 'all') type = null
      if (usage === 'all') usage = null
      if (gameclass === 'all') gameclass = null
      if (operator === 'all') operator = null
      if (reset) {
        this.resetGoods({ search, type, usage, gameclass, operator })
      }
      this.findGoods({ search, type, usage, gameclass, operator })
    },
    // 依據 route 重設所有過濾條件
    async resetSelector () {
      const { search, type, operator, usage, gameclass } = this.$route.query
      this.type = type || 'all'
      this.search = search || ''
      this.operator = operator * 1 || 'all'
      this.usage = usage || 'all'
      this.gameclass = gameclass || 'all'
      this.fetchData(true)
    },

    async newGoods () {
      this.goodsData = {
        name: '',
        description: '',
        type: 'Card',
        usage: 'FreeGame',
        cashPrice: 0,
        gemPrice: 0,
        gemReward: 0,
        pointPrice: 0,
        pointReward: 0,
        actPointPrice: 0,
        actPointReward: 0,
        laijiCoinPrice: 0,
        laijiCoinReward: 0,
        stakePrice: 0,
        vipPointReward: 0,
        discount: 0,
        data: '',
        isHot: false,
        isFirst: false,
        eShopId: '',
        thumbnail: '',
        image: '',
        mechanism: ['slot1', 'slot2', 'slot3'],
        operators: this.allOperators.map(o => o._id),
        provider: '',
        games: [],
        gameClass: []
      }
      this.goodsEditorType = 'new'
      this.goodsEditorVisible = true
    },
    async onGoodsUpdate () {
      if (!this.goodsData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeGoodsEditor()
      }

      if (this.goodsData.data) this.goodsData.data = JSON.parse(this.goodsData.data)
      let [err] = await to(this.createGoods({ data: this.goodsData }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create goods error'))
      }

      this.$Message.success(this.$t('Create goods successful'))
      this.closeGoodsEditor()

      this.fetchData(true)
    },

    closeGoodsEditor () {
      this.goodsEditorVisible = false
      setTimeout(() => {
        this.goodsEditorType = null
        this.goodsData = null
      }, 500)
    },
    afterClickName (name) {
      if (this.search !== name) {
        this.search = name
        this.onSearchInputChange()
      }
    }
  },
  async mounted () {
    await this.resetSelector()
  },
  beforeDestroy () {
    this.resetGoods()
  }
}

</script>

<style lang="sass">
</style>
